<template>
    <div>

        <b-card>
            <app-view-table :title="trans('settings-functions',182)" :loading="loading_list" :isTitleTotal="true" :isSearch="true" :filters.sync="filters" :items="items" :fields="fields" :customColumns="customColumns">     
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeName(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeDescription(cell.data.item)">{{trans('change-description',3)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeDescriptionStudent(cell.data.item)" v-if="cell.data.item.student == 1">{{trans('settings-change-function-student', 167)}}</b-dropdown-item>
                </template>   

                 <template v-slot:description="cell">
                    <span v-if="cell.data.item.description != null && cell.data.item.description.length > 60" data-toggle="tooltip" :title="cell.data.item.description">{{cell.data.item.description.substring(0, 60)}} ...</span>
                    <span v-else >{{cell.data.item.description}}</span>
                </template>
            </app-view-table>

              <settings-type-change v-if="mTypeEdit.show" :show.sync="mTypeEdit.show" :result.sync="mTypeEdit.result" :args="mTypeEdit.args" :return.sync="items" /> 
              <settings-type-description-change v-if="mTypeDescriptionEdit.show" :show.sync="mTypeDescriptionEdit.show" :result.sync="mTypeDescriptionEdit.result" :args="mTypeDescriptionEdit.args" :return.sync="items" />
        </b-card>  
    </div>
</template>
<script>


import axios from "@axios";
import SettingsTypeChange from './SettingsTypeChange.vue'
import SettingsTypeDescriptionChange from './SettingsTypeDescriptionChange.vue'

export default {
     components:{
        SettingsTypeChange,
        SettingsTypeDescriptionChange
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "name_short", label: this.trans('short-name',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true},
                { key: "scale_value", label: this.trans('scale',182), thStyle: { "min-width": "100px", "max-width": "150px" }, visible: true},
                { key: "description_status", label: this.trans('description',182), thStyle: { "min-width": "150px", "max-width": "150px" }, tdClass: "table-column-wrap", visible: true},
                { key: "zzp", label: 'ZZP', thStyle: { "min-width": "100px", "max-width": "100px" },  visible: true, sortable: false},
                { key: "is_student", label: 'Student', thStyle: { "min-width": "100", "max-width": "100px" }, visible: true, sortable: false},
            ],
            items: [],
            customColumns: ["description"],

            mTypeEdit: { show: false, args: {
                    type_data:{
                        id: null,
                        name: null,
                        name_short: null,
                        scale_value: null,
                        description: null
                    },
                    filters: null,
                }, result: 0, return: null },

            mTypeDescriptionEdit: { show: false, args: {
                    type_data:{
                        id: null,
                        description: null,
                        description_student: null,
                        description_article_1: null,
                        student: false
                    },
                    is_student: false,
                    filters: null,
                }, result: 0, return: null },
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/getTypeList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        changeName(item) {
            this.mTypeEdit.args.type_data.id = item.id;
            this.mTypeEdit.args.type_data.name = item.name
            this.mTypeEdit.args.type_data.name_short = item.name_short;
            this.mTypeEdit.args.type_data.scale_value = item.scale_value;
            this.mTypeEdit.args.type_data.description = item.description ? item.description : '';
            this.mTypeEdit.args.filters = this.filters;
            this.mTypeEdit.show = true;
            
        },

        changeDescription(item) {
            this.mTypeDescriptionEdit.args.type_data.id = item.id;
            this.mTypeDescriptionEdit.args.type_data.description = item.description ? item.description : '';
            this.mTypeDescriptionEdit.args.filters = this.filters;
            this.mTypeDescriptionEdit.show = true;
            
        },

        changeDescriptionStudent(item){
            this.mTypeDescriptionEdit.args.type_data.id = item.id;
            this.mTypeDescriptionEdit.args.type_data.description = item.description ? item.description : '';
            this.mTypeDescriptionEdit.args.type_data.description_student = item.description_student ? item.description_student : '';
            this.mTypeDescriptionEdit.args.type_data.description_article_1 = item.description_article_1 ? item.description_article_1 : '';
            this.mTypeDescriptionEdit.args.type_data.student = item.student;
            this.mTypeDescriptionEdit.args.is_student = true;
            this.mTypeDescriptionEdit.args.filters = this.filters;
            this.mTypeDescriptionEdit.show = true;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    }
 
};
</script>
<style scoped>

</style>